body {
    margin: 0;
    font-family: "Poppins", sans-serif;
}

input,
textarea,
mat-select {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: normal;
    display: block;
    padding: 10px 10px;
    border: solid 2px var(--white);
    background-color: var(--white);
    border-radius: 10px;
    transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
        background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    width: calc(100% - 20px);
    outline: 0;
    color: inherit;
    font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 5px;
}

p {
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
    font-size: calc(14.4px + 3.6 * ((100vw - 700px) / 1099));
    padding: 0;
    box-sizing: inherit;
    color: var(--black);
    z-index: 0;
}

a {
    color: var(--primary-accent);
    transition: color 0.075s linear;
    cursor: pointer;
    text-decoration: underline;
}

button {
    // width: 100%;
    &:focus {
        outline: 0;
    }
    position: relative;
    background-color: var(--white);
    border: solid 2px var(--primary);
    margin: 2px;
    color: var(--primary);
    border-radius: 10px;
    padding: 8px 16px;
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
        border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    // &:hover {
    //     opacity: 0.5;
    // }

    &.-large {
        width: 100%;
    }

    &.-signup {
        color: var(--primary-accent);
        border: solid 2px var(--primary-accent);
    }

    &.-primary {
        background-color: var(--primary);
        border: solid 2px var(--primary);
        color: var(--white);
        font-weight: 700;

        &:hover:not(:disabled) {
            background-color: var(--white);
            border: solid 2px var(--white);
            color: var(--primary);
        }
    }

    &.-secondary {
        background-color: var(--secondary);
        border: solid 2px var(--secondary);
        color: var(--white);
        font-weight: 700;

        &.-outline {
            border: solid 2px var(--secondary);
        }

        &.next {
            min-width: 280px;
        }

        &:hover:not(:disabled) {
            background-color: var(--white);
            border: solid 2px var(--white);
            color: var(--secondary);
        }
    }

    &.disconnect {
        border-color: var(--secondary);
        color: var(--secondary);

        &:hover:not(:disabled) {
            background-color: var(--white);
            border: solid 2px var(--white);
            color: var(--secondary);
        }
    }

    &.-next-step  {
        background-color: var(--secondary);
        color: var(--white);

        &:hover:not(:disabled) {
            background-color: var(--white);
            border: solid 2px var(--white);
            color: var(--secondary);
        }
    }

    &.disabled {
        background-color: lightgray;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
            color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:disabled {
        border: solid 2px lightgray;
        color: lightgrey;
        opacity: 0.5;
        cursor: not-allowed;
    }
}

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: var(--tertiary) !important;
}

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: var(--tertiary) !important;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 15px;
}
